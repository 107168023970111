<template>
  <div class="managingPeople hp100 bbox" v-loading="loading" >
    <!-- 卡片视图 -->
    <!-- <el-card> -->
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col style="text-align:right;">
          <!-- <el-button plain class="add-column-btn bbox" @click="gotoDetail('')" type="primary">
                       <i class="el-icon-plus fw900 f16"></i> 新增</el-button> -->
        <el-button type="primary" @click="goBack()">返回</el-button>
        </el-col>
        <!-- <el-col style="width: 350px">
          <div class="flex align-center justify-end header-search-box">
            <el-input
              placeholder="请输入手机号查询"
              clearable
              v-model="searchInput"
              class="header-search-input ml10"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                class="textblue searchBtn"
                @click="search()"
              ></el-button>
            </el-input>
          </div>
        </el-col> -->
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        fit
        class="heightfix"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column label="编号" type="index" width="50">
        </el-table-column>
        <el-table-column label="订单号" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.orderNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备编号" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceId }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单开始时间" align="center" width="140px" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.createDt | dataFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付金额" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.orderPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="使用人头像" align="center" prop="memberName">
          <template slot-scope="scope">
            <el-avatar
              :src="scope.row.memberLogo"
              shape="square"
              :size="60"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="使用人昵称" align="center" prop="memberName">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus == 0">---</span>
            <span v-else>{{ scope.row.memberName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户手机号" align="center" prop="memberName">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus == 0">---</span>
            <span v-else>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属电子围栏" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.electronicFenceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="管理员" align="center" prop="memberCode">
          <template slot-scope="scope">
            <span>{{ scope.row.adminName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="" align="center" prop="id" v-if="false">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="付款状态" align="center" prop="memberCode">
          <template slot-scope="scope">
            <span>{{ judgeStatus(scope.row.orderStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单结束时间" align="center" width="140px" prop="orderPrice">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus == 0">---</span>
            <span v-else>{{ scope.row.orderOverTime | dataFormat }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看详情"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.id, scope.row.userCode)"
              ></i>
            </el-tooltip>

          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      tableData: [],
      loading: true,
      id: "",
      searchInput: "",
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          phone: ""
        }
      },
      userlist: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      info:'',
    };
  },
  created() {
    // this.queryInfo.page=this.$route.query.page;
    // this.queryInfo.limit=this.$route.query.limit;
    this.queryInfo.condition.memberCode = this.$route.query.memberCode
    this.info = this.$route.query.queryInfo
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //返回
    goBack(){
      this.$router.push({path:'/member',query:{queryInfo:this.info}})
    },
    //条件查询
    search() {
      this.queryInfo.condition.phone = this.searchInput;
      this.getListData();
    },
    judgeStatus(orderStatus) {
      if (orderStatus === 0) {
        return "未完成";
      } else if (orderStatus === 1) {
        return "未支付";
      } else if (orderStatus === 2) {
        return "已完成";
      } else {
        return "";
      }
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/financeMag/details",
        query: {
          id: id,
          page: that.queryInfo.page,
          limit: that.queryInfo.limit
        }
      });
    },
    getListData() {
      // 获取列表
      var that = this;
      var data = that.queryInfo;
      //   console.log(data)
      that.$http
        .post("/memberOrder/list3", {
          currPage: data.currPage,
          pageSize: data.pageSize,
          condition: data.condition
        })
        .then(function(response) {
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-table::before{
  height: 0;
}
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 64px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
.heightfix {
  overflow-y: scroll;
  max-height: 650px;
}
</style>
